import React from 'react'
import Heading from '../components/typograph/Heading'
import Layout from '../components/layout/Layout'
import PageContainer from '../components/layout/PageContainer'
import Hero from '../components/hero/Hero'
import IlustratedContent from '../components/ilustratedParagraph/IlustratedContent'
import styled from 'styled-components'
import {
  LIGHT_GRAY,
  GRAY,
  DARK_GREEN,
  WHITE,
  ACQUA,
  TEXT,
  ORANGE,
} from '../constants/Colors'

import FacebookSquareBrands from '../images/icons/facebook-square-brands'
import InstagramBrand from '../images/icons/instagram-brands'

import contactImage from '../images/Banner-Contato-Desk.jpg'
import contactImageMobile from '../images/Banner-Contato-Mobile.jpg'
import contato from '../images/Contato.png'
import { FONT_FAMILY_1 } from '../constants/FontFamily'
import ContactForm from '../components/forms/ContactForm'
import WhatsAppBrand from '../images/icons/whatsapp-brands'

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  margin-bottom: 64px;
  margin-top: 64px; 

  & a,
  & a:visited,
  & a:hover {
    color: ${ORANGE};
    text-decoration: none;
    font-weight: bold;
  }

  @media (max-width: 857px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const AddressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 32px;
  margin-top: 0px;
  padding: 0;

  & a,
  & a:visited,
  & a:hover {
    color: ${ORANGE};
    text-decoration: none;
    font-weight: bold;
  }

  & div {
    margin-left: 40px;
  }

  & iframe {
    max-width: 450px;
  }

  @media (max-width: 1080px) {
    font-size: 14px;
  }

  @media (max-width: 950px) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
    & div {
      width: 100%;
      margin-bottom: 20px;
      margin-left: 0px;
      text-align: center;
    }

    & iframe {
      max-width: 100%;
    }
  }

  @media (max-width: 623px) {
    & div {
      width: 100%;
      padding: 10px;
    }
  }
`

const GrayContainer = styled.div`
  background-color: ${LIGHT_GRAY};
  padding: 24px 0;
  z-index: -1;
  width: 100%;
  left: 0;
`
const Contato = () => (
  <Layout
    title="Contato - HDScience"
    meta={[
      {
        name: 'keywords',
        content:
          'homeopatia, veterinária, cromoterapia, curso, cursos, mini-curso, mini-cursos',
      },
      {
        property: 'og:url',
        content: 'http://www.hdscience.com.br/contato',
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Contato - HDScience' },
    ]}
  >
    <Hero image={contactImage} mobileImage={contactImageMobile}>
      <Heading
        type="h1"
        style={{ color: DARK_GREEN, textAlign: 'center', paddingTop: 80 }}
      >
        CONTATO
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <TextContainer>
        <div>
          <IlustratedContent
            image={contato}
            secondary={true}
            style={{
              borderRadius: '20px',
              border: `1px solid ${GRAY}`,
              maxWidth: '240px',
              padding: 20,
              backgroundColor: WHITE,
              marginLeft: '24px',
              marginRight: '30px',
            }}
          >
            <div
              style={{
                marginTop: 32,
                backgroundColor: DARK_GREEN,
                width: '278px',
                marginLeft: '-20px',
                border: `1px solid ${LIGHT_GRAY}`,
                padding: '26px 0',
              }}
            >
              <span
                style={{
                  fontSize: 24,
                  fontFamily: FONT_FAMILY_1,
                  color: WHITE,
                }}
              >
                Redes Sociais
              </span>
              <br />
              <a
                href="https://www.instagram.com/hdscienceveterinaria/"
                target="_blank"
                rel="external noopener noreferrer"
              >
                <InstagramBrand />
              </a>
              <a
                href="https://www.facebook.com/hdscience/"
                target="_blank"
                rel="external noopener noreferrer"
              >
                <FacebookSquareBrands />
              </a>
            </div>
            <Heading
              type="h4"
              style={{ margin: '32px 0 16px 0' }}
              color={ACQUA}
            >
              E-mail
            </Heading>
            <a href="mailto:secretaria@hdscience.com.br">
              secretaria@hdscience.com.br
            </a>
          </IlustratedContent>
        </div>
        <div
          style={{
            textAlign: 'left',
            maxWidth: 700,
            color: TEXT,
          }}
        >
          <Heading type="h2" color={DARK_GREEN}>
            Fale Conosco
          </Heading>
          <ContactForm />
        </div>
      </TextContainer>
    </PageContainer>
    <GrayContainer>
      <PageContainer>
        <AddressContainer>
          <iframe 
            title="mapaSCS"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.503733447063!2d-46.570640000000004!3d-23.6221246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5cc1c3baf93b%3A0xbfb2ebfc2355937a!2sR.%20S%C3%A3o%20Paulo%2C%201096%20-%20Santa%20Paula%2C%20S%C3%A3o%20Caetano%20do%20Sul%20-%20SP%2C%2009530-211!5e0!3m2!1spt-BR!2sbr!4v1671588528863!5m2!1spt-BR!2sbr" 
            width="100%" 
            height="335" 
            style={{border:0}} 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"></iframe>
          <div style={{ lineHeight: 1.5, color: TEXT }}>
            <Heading type="h2" style={{ padding: 0, margin: 0, lineHeight: 1 }}>
              Unidade
              <br /> São Caetano
              <br /> Polo Ebramec
            </Heading>
            <br />
            Rua São Paulo, 1.096
            <br />
            São Caetano do Sul/SP
            <br />
            <b>
              Estacionamento em horário <br />
              comercial ao lado do local
              <br />
              <br />
              11 2376.4447 / 
              <a href='https://api.whatsapp.com/send?phone=5511991760079' style={{color:ORANGE, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}} target='_blank' rel='external noopener noreferrer'> 11 99176.0079 <WhatsAppBrand swapColor /></a>
              <br />
              <a href='https://api.whatsapp.com/send?phone=5511981868532' style={{color:ORANGE, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}} target='_blank' rel='external noopener noreferrer'>11 98186.8532</a> ou 
              <a href='https://api.whatsapp.com/send?phone=5511996315666' style={{color:ORANGE, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}} target='_blank' rel='external noopener noreferrer'> 11 99631.5666 <WhatsAppBrand swapColor /></a>
            </b>
          </div>
        </AddressContainer>
      </PageContainer>
    </GrayContainer>
  </Layout>
)

export default Contato
