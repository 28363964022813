import React, {PureComponent} from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ButtonContainer from '../buttons/Button';
import { GRAY, RED } from '../../constants/Colors';
import styled from 'styled-components';
import { FONT_FAMILY_2 } from '../../constants/FontFamily';

const InputContainer = styled.div`
    width: ${({width}) => width || '100%'} ;
    padding: 0;
    display: inline-block;

    & span{
        font-size: 12px;
        color: red;
        margin-top: -10px;
        padding: 0;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`

const Label = styled.label`
    font-family: ${FONT_FAMILY_2};
    display:inline-block;
    width: 80px;
    padding: 0;
    margin: 0;
    text-align: ${({center}) => center ? 'center' : 'left'};

    @media (max-width: 500px) {
        text-align: left;
    }
`

const Input = styled.input`
    border-radius: 5px;
    border: 1px solid ${GRAY};
    font-family: ${FONT_FAMILY_2};
    font-size: 14px;
    padding: 5px;
    width: calc(100% - 95px);
    margin: 0;

    &.invalid{
        border: 2px solid ${RED};
    }
`

const TextArea = styled.textarea`
    border-radius: 5px;
    border: 1px solid ${GRAY};
    resize: none;
    font-family: ${FONT_FAMILY_2};
    font-size: 16px;
    padding: 5px;
    width: calc(100% - 15px);
    height: 100px;

    &.invalid{
        border: 2px solid ${RED};
    }
`

class ContactForm extends PureComponent { 

    state = {
        validName: true,
        name: '',
        validPhone: true,
        fone: '',
        validMail: true,
        mail: '',
        validMessage: true,
        message: '',
        nameBlured: false,
        phoneBlured: false,
        mailBlured: false,
        messageBlured: false
    }

    validateName = () => {
        const value = this.state.name

        if(value && value.length >= 3){
            this.setState({validName: true, nameBlured: true});
            return true;
        }else{
            this.setState({validName: false, nameBlured: true});
            return false;
        }

    }

    validateMessage = () => {
        const value = this.state.message

        if(value && value.length >= 3){
            this.setState({validMessage: true, messageBlured: true});
            return true;
        }else{
            this.setState({validMessage: false, messageBlured: true});
            return false;
        }
    }

    validateFone = () => {
        const value = this.state.fone

        if(value && value.length >= 8 && value.match(/[0-9)(.-]+/)){
            this.setState({validPhone: true, phoneBlured: true});
            return true;
        }else{
            this.setState({validPhone: false, phoneBlured: true});
            return false;
        }
    }

    validateMail = (e) => {
        const value = this.state.mail

        if(value && value.match(/[a-zA-Z0-9_]+@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)+/)){
            this.setState({validMail: true, mailBlured: true});
            return true
        }else{
            this.setState({validMail: false, mailBlured: true});
            return false
        }
    }

    handleToken = async () => {
        const tokenResult = await this.props.googleReCaptchaProps.executeRecaptcha('contact_form');
        this.setState({token: tokenResult});

        return tokenResult;
    }

    handleSubmit = async (event) => {        
        if(!this.validateName() || !this.validateFone()|| !this.validateMail() || !this.validateMessage() ){
            return;
        }

        if(!await this.handleToken()) return;
        
        this.finalForm.submit();

    }

    allFieldsValid = () => {
        const {
            nameBlured,
            phoneBlured,
            mailBlured,
            validMessage,
        } = this.state;

         return nameBlured && phoneBlured && mailBlured && validMessage;
    }
    
    render(){
        return  ( // http://localhost:8080/contato.php
            <>
            <form id="contact-form" style={{ lineHeight: 2.5 }} 
                onSubmit={(e) => { 
                    e.preventDefault(); 
                    this.handleSubmit(e);
                    }}>
                <InputContainer>
                    {this.state.validName ? '' : <span>* Nome é obrigatório<br/></span>}
                    <Label>Nome</Label>
                    <Input type="text" 
                        ref={this.nameInput}
                        name='name' 
                        onBlur={this.validateName}
                        className={this.state.validName ? '' : 'invalid'}
                        value={this.state.name}
                        onChange={(e) => this.setState({name: e.target.value})}
                        />
                </InputContainer>
                <InputContainer width='40%'>
                    {this.state.validPhone ? '' : <span>* Telefone é obrigatório<br/></span>}
                    <Label>Telefone</Label>
                    <Input type="tel" 
                        name='fone' 
                        onBlur={this.validateFone} 
                        value={this.state.fone}
                        onChange={(e) => this.setState({fone: e.target.value})}
                        className={this.state.validPhone ? '' : 'invalid'}
                    />
                </InputContainer>
                <InputContainer width='60%'>
                    {this.state.validMail ? '' : <span>* E-mail é obrigatório<br/></span>}
                    <Label center>E-mail</Label>
                    <Input type="email" 
                        name='mail' 
                        onBlur={this.validateMail}
                        value={this.state.mail}
                        onChange={(e) => this.setState({mail: e.target.value})}
                        className={this.state.validMail ? '' : 'invalid'}
                    />
                </InputContainer>
                <InputContainer>
                    {this.state.validMessage ? '' : <span>* Escreva uma mensagem<br/></span>}
                    <Label>Mensagem</Label>
                    <TextArea 
                        name='message'  
                        onBlur={this.validateMessage}
                        onChange={(e) => { 
                            this.setState({message: e.target.value});
                            this.state.messageBlured && this.validateMessage();
                        }}
                        value={this.state.message}
                        className={this.state.validMessage ? '' : 'invalid'}
                    />
                </InputContainer>
                *Preencha todos os campos para ativar o envio.
                <ButtonContainer 
                    style={{ float: 'right', marginTop: 24 }} 
                    disabled={!this.allFieldsValid()}
                    >
                    Enviar
                </ButtonContainer>
            </form>
            <form ref={(form) => this.finalForm = form} method="POST" action="/contato.php">
                <input type='hidden' name="name" value={this.state.name} />
                <input type='hidden' name="fone" value={this.state.fone} />
                <input type='hidden' name="mail" value={this.state.mail} />
                <input type='hidden' name="message" value={this.state.message} />
                <input type='hidden' name="token" value={this.state.token} />
            </form>
            </>
        )
    }
}

export default withGoogleReCaptcha(ContactForm);